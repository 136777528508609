<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Regalverwaltung</h4>
                            <p>Liste aller Regale</p>
                        </div>

                        <div class="card-tools">
                            <div class="row align-items-center">
                                <div class="col-md-12">
                                    <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                        <ul class="navbar-nav">  

                                            <!-- <li class="nav-item mr-1">
                                                <div class="input-group input-group-sm" style="margin-top: 0;">
                                                    <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="getResults" :value="search" @input="$store.commit('dealers/changeSearch', $event.target.value)" />
                                                    <button type="submit" class="btn btn-default btn-sm" @click.prevent="getResults">
                                                        <i class="fas fa-fw fa-search"></i>
                                                    </button>
                                                    <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                        <i class="fas fa-fw fa-times"></i>
                                                    </button>
                                                </div>
                                            </li> -->

                                            <!-- <li class="nav-item">
                                                <button type="button" class="btn mr-1  btn-sm btn-primary" @click="createModal" v-if="$auth.check('dealers.create')">
                                                    <i class="fas fa-fw fa-plus"></i>
                                                </button>
                                            </li> -->

                                            <li class="nav-item">
                                                <button type="button" class="btn btn-sm btn-default" @click="getResults">
                                                    <i class="fas fa-fw fa-sync"></i>
                                                </button>
                                            </li>
                                        </ul>                    
                                    </nav>
                                </div>
                            </div>
                            
                            
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                        </div>
                    </div>

                    <div class="card-body">
                        <template v-if="shelves.data.length > 0">
                        <div class="table-responsive">
                            <table class="table table-hover table-sm">
                                <thead>
                                    <!-- <th><input type="checkbox" v-model="selectAll" /></th> -->
                                    <th scope="col">
                                        <a href="#" @click.prevent="changeSort('name')">Name</a>
                                        <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col">
                                        Ebenen
                                    </th>
                                    <!-- <th style="width: 140px;">Aktionen</th>                  -->
                                </thead>
                                <tbody>
                                    <tr v-for="(shelf) in shelves.data" :key="shelf.id" @click="selectShelf(shelf.id)">
                                        <!-- <td><input type="checkbox" :value="shelf.id" v-model="selectedShelf"/></td> -->
                                        <td><span class="badge badge-pill badge-dark">{{ shelf.name }}</span></td>
                                        <td>{{ shelf.layers }}</td>                                 
                                        <!-- <td> -->
                                            <!-- <router-link class="mr-1 btn btn-info btn-xs" :to="{name: 'dealer-orders.create', params: {id: dealer.id}}" v-if="$auth.check('dealer_orders.create')"><i class="fas fa-fw fa-truck"></i></router-link>
                                            <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'dealers.show', params: {id: dealer.id}}" v-if="$auth.check('dealers.show')"><i class="fas fa-fw fa-eye"></i></router-link>
                                            <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'dealers.details', params: {id: dealer.id}}" v-if="$auth.check('dealers.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                            <b-button size="xs" @click="deleteDealer(dealer.id)" variant="danger" v-if="$auth.check('dealers.destroy')"><i class="fas fa-fw fa-trash"></i></b-button> -->
                                        <!-- </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </template>

                        <template v-else>
                            <h5>Keine Regale gefunden</h5>
                        </template>
                    </div>

                    <div class="card-footer">
                        <template v-if="shelves.data.length > 0">
                            <div class="row align-items-center">
                                <div class="col-md-7">
                                    <pagination class="float-left" :data="shelves" @pagination-change-page="getResults" :limit="3"></pagination>
                                </div>
                                <div class="col-md-3">
                                    <span class="float-right">Anzeige Eintrag {{ shelves.meta.from }} - {{ shelves.meta.to }} von {{ shelves.meta.total }}</span>
                                </div>
                                <div class="col-md-2">
                                    <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                        <option value="25">25 Regale</option>
                                        <option value="50">50 Regale</option>
                                        <option value="75">75 Regale</option>
                                        <option value="100">100 Regale</option>
                                        <option value="125">125 Regale</option>
                                        <option value="150">150 Regale</option>
                                    </select>
                                </div>
                            </div>                        
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <div class="card card-outline card-success">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Regalverwaltung</h4>
                            <p v-if="selectedShelf == null">Liste aller Regale</p>
                            <p v-else>{{ selectedShelf.name }}</p>
                        </div>
                    </div>
                    <div class="card-body">
                        <template v-if="selectedShelf != null">
                            <div class="row" v-for="layer in selectedShelf.layers" :key="layer">
                                <div class="col-md-12">
                                    <div class="row justify-content-center">
                                        <div class="col-md-12 text-center">
                                            <h4><strong>Ebene {{layer}}</strong></h4>
                                        </div>
                                    </div>
                                    <template v-if="layer in selectedShelf.articles">
                                        <div class="row justify-content-center">
                                            <div class="col-md-3" style="display: flex; flex-direction: column;" v-for="article in selectedShelf.articles[layer]" :key="article.id">
                                                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                                    <router-link :to="{name: 'articles.details', params: {id: article.id}}"><img :src="article.image" class="card-img-top"></router-link>
                                                    <div class="card-body">
                                                        <h5 class="card-title"><strong>{{ article.name }}</strong></h5><br><br>
                                                        <table>
                                                            <tr>
                                                                <td>Art-Nr:</td>
                                                                <td><strong>12345</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Hersteller:</td>
                                                                <td><strong>Hofladen-Sauerland.de</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>VK (Brutto):</td>
                                                                <td><strong>{{ article.price | toCurrency }}</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td>EK (Netto):</td>
                                                                <td><strong>{{ article.purchase_price | toCurrency }}</strong></td>
                                                            </tr>
                                                        </table>
                                                        <!-- <router-link class="mr-1 btn btn-primary" :to="{name: 'articles.details', params: {id: article.id}}" v-if="$auth.check('articles.edit')"><i class="fas fa-fw fa-edit"></i></router-link> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 text-center">
                                                <h4>Dieses Fach hat noch keine Artikel</h4>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <h3>Bitte wähle links ein Regal</h3>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>

export default {
  title: "Regalverwaltung",
  name: "Regalverwaltung",

  data() {
    return {
        shelves: {
            data: [],
        },
        params: {
            sort_field: 'name',
            sort_direction: 'asc',
            per_page: 75,
            filter: 'all',
        },
        search: '',
        page: 1,
        selectedShelf: null,
    }
  },

  methods:{
    changeSort(field) {
        if(this.params.sort_field === field) {
            this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';

        }
        else{
            this.params.sort_field = field;
            this.params.sort_direction = 'asc';
        }
    },

    selectShelf(id){
        this.axios
            .get("/shelves/" + id)
            .then((response) => {
                this.selectedShelf = response.data.data;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
    },

    getResults(page) {
        if(page == undefined)
        {
            page = this.page 
        }
        this.axios
            .get("/shelves", {
                params: {
                    page,
                    search: this.search,
                    ...this.params
                }
            })
            .then((response) => {
                this.shelves = response.data;
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
    }
  },


  created() {
        this.getResults();
    }

}

</script>